<template>
  <v-card :dark="$dark.get()">
    <v-card-title class="flex justify-center title header">
      <div class="title__conteiner">
        <p class="title__text">Отчеты</p>
      </div>
      <div class="title__conteiner">
        <v-btn color="#5d4bd0" dark @click="showModal = !showModal"
          >Готовые</v-btn
        >
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <loader v-if="loading"></loader>
      <clients-list-modal :show-modal="showModal" :get-list="getList()" />

      <!-- для супер админа -->
      <v-simple-table height="64vh" class="table">
        <thead>
          <tr class="table_header">
            <th v-for="header in tHead" :key="header.id">{{ header.name }}</th>
          </tr>
        </thead>
        <tbody v-if="!$root.isProvider">
          <ReportFile :download="downloadClientsReport" text="Клиенты">
            <template v-slot:type>
              <v-select
                v-model="clientsInput.lifeTimePeriods"
                label="Период"
                :items="createPeriods(24)"
                solo
                dense
                hide-details
                class="file-input"
              ></v-select>
            </template>
            <template v-slot:filials>
              <v-select
                v-model="clientsInput.isMonth"
                label="Отчет по:"
                :items="clientsPeriod"
                item-value="value"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
              ></v-select>
            </template>
          </ReportFile>

          <ReportFile :download="downloadSalesReport" text="Продажи">
            <template v-slot:type>
              <v-select
                v-model="salesTypeId"
                label="Тип отчета"
                :items="salesReportTypes"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
            <template v-slot:filials>
              <v-select
                v-if="salesTypeId !== 3"
                v-model="salesDepartment"
                label="Филиалы"
                :items="USER.userInfo.departments"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
          </ReportFile>

          <ReportFile
            :download="downloadSalesReport"
            text="Онлайн продажи"
            online
          >
            <template v-slot:type>
              <v-select
                v-model="salesTypeId"
                label="Тип отчета"
                :items="salesReportTypes"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
            <template v-slot:filials>
              <v-select
                v-if="salesTypeId !== 3"
                v-model="salesDepartment"
                label="Филиалы"
                :items="USER.userInfo.departments"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
          </ReportFile>
          <!-- Заказы -->
          <ReportFile :download="downloadOrder" text="Заказы">
            <template v-slot:type>
              <v-select
                v-model="input.statusId"
                label="статус"
                :items="allStatus"
                item-value="id"
                item-text="title"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
            <template v-slot:filials>
              <v-select
                v-if="input.statusId === 666777666"
                v-model="input.departmentId"
                label="Филиалы"
                :items="USER.userInfo.departments"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
          </ReportFile>
          <ReportFile :download="downloadStatus" text="Статусы">
            <template v-slot:startTimePicker>
              <v-time-picker
                v-model="statusInput.timeStart"
                format="24hr"
                use-seconds
              >
              </v-time-picker>
            </template>
            <template v-slot:endTimePicker>
              <v-time-picker
                v-model="statusInput.timeEnd"
                format="24hr"
                use-seconds
              >
              </v-time-picker>
            </template>
          </ReportFile>

          <ReportFile
            :download="downloadCompensationReport"
            text="Компенсации работников"
          />
          <ReportFile :download="downloadReward" text="Премии" />
          <ReportFile :download="downloadDistanceReport" text="Дистанция" />
          <ReportFile :download="downloadAirbaReport" text="Airba" />
          <ReportFile :download="downloadDepartment" text="Филиалы" />
          <ReportFile :download="downloadProviders" text="Поставщики" />
        </tbody>

        <!-- для поставщика -->
        <tbody v-else>
          <ReportFile :download="downloadSalesReport" text="Продажи">
            <template v-slot:type>
              <v-select
                v-model="salesTypeId"
                label="Тип отчета"
                :items="salesReportTypes.filter((item) => item.id !== 3)"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
            <template v-slot:filials>
              <v-select
                v-if="salesTypeId !== 3"
                v-model="salesDepartment"
                label="Филиалы"
                :items="USER.userInfo.departments"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
          </ReportFile>

          <ReportFile
            :download="downloadSalesReport"
            text="Онлайн продажи"
            online
          >
            <template v-slot:type>
              <v-select
                v-model="salesTypeId"
                label="Тип отчета"
                :items="salesReportTypes.filter((item) => item.id !== 3)"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
            <template v-slot:filials>
              <v-select
                v-if="salesTypeId !== 3"
                v-model="salesDepartment"
                label="Филиалы"
                :items="USER.userInfo.departments"
                item-value="id"
                item-text="name"
                solo
                dense
                hide-details
                class="file-input"
                :rules="[$validate.required]"
              ></v-select>
            </template>
          </ReportFile>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import loader from "../components/Loader";
import ReportFile from "../components/File/ReportFile";
import ClientsListModal from "../components/File/ClientsListModal";
import ShowMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    loader,
    ReportFile,
    ClientsListModal,
  },
  data() {
    return {
      allStatus: [],
      loading: true,
      file: null,
      edit: false,
      input: {
        departmentId: null,
        statusId: null,
      },
      statusInput: {
        timeStart: new Date().toISOString().substring(11, 19),
        timeEnd: "23:59:59",
      },
      clientsInput: {
        lifeTimePeriods: 12,
        isMonth: true,
      },
      clientsPeriod: [
        { name: "Месяцам", value: true },
        { name: "Неделям", value: false },
      ],
      department: [],
      showModal: false,
      salesTypeId: null,
      salesDepartment: null,
      salesReportTypes: [
        { id: 1, name: "Свернутый" },
        { id: 2, name: "Развернутый" },
        { id: 3, name: "С площадки" },
      ],
      tHead: [
        { id: 0, name: "Название" },
        { id: 1, name: "Тип отчета" },
        { id: 2, name: "Название филиала" },
        { id: 3, name: "Дата с" },
        { id: 4, name: "Дата по" },
        { id: 5, name: "" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      FILE: "File/STATE",
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
    }),
  },

  async created() {
    this.loading = true;
    this.checkPower();
    await this.getAllDepartment(this.$root.city);
    this.allStatus = await api.Status.statusAll();
    if (this.allStatus.type === "success") {
      this.allStatus = this.allStatus.data;
      this.allStatus.push({
        createdAt: "-",
        id: 666777666,
        keyName: "All",
        name: "Все",
        title: "Все",
        updatedAt: "-",
      });
    }
    this.department = this.DEPARTMENT.cityDepartment;
    if (this.USER.userInfo.user.roleId === 5) {
      this.isProvider = true;
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getOrderFile: "File/EXPORT_ORDER_FILE",
      getAllDepartment: "Department/GET_DEPARTMENT",
      getProvidersExel: "File/REPORT_PROVIDERS",
      fileEdit: "File/FILE_EDIT",
      reportDep: "File/REPORT_DEP",
      reportStatus: "File/REPORT_FILE",
      reportReward_v2: "File/REPORT_REWARD_V2",
      reportClients: "File/REPORT_CLIENTS",
      reportDistance: "File/REPORT_DISTANCE",
      reportRatings: "File/REPORT_RATINGS",
      reportSalesOpen: "File/REPORT_SALES_OPEN",
      reportSalesClose: "File/REPORT_SALES_CLOSE",
      reportPlatform: "File/REPORT_PLATFORM",
      reportCompensation: "File/REPORT_COMPENSATION",
      reportAirba: "File/REPORT_AIRBA",
    }),

    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },

    async downloadDepartment(startDate, endDate) {
      this.loading = true;
      let file = await this.reportDep({
        startDate,
        endDate: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
      });
      downloadFile(file);
      this.loading = false;
    },

    async downloadProviders(startDate, endDate) {
      this.loading = true;
      let body = {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
      };
      let file = await this.getProvidersExel(body);
      if (file.type !== "error") {
        downloadFile(file);
      }
      this.loading = false;
    },

    async downloadOrder(startDate, endDate) {
      if (!this.input.statusId) {
        ShowMessage("Выберите статус");
        return;
      }
      let status = null;
      if (this.input.statusId !== 666777666) {
        status = this.input.statusId;
      }
      this.loading = true;
      let payload = {
        cityId: this.$root.city,
        departmentId: this.input.departmentId,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
        statusId: status,
      };
      let fileName = await this.getOrderFile(payload);
      downloadFile(fileName);
      this.loading = false;
    },

    async downloadStatus(startDate, endDate) {
      this.loading = true;
      let body = {
        startDate: startDate + "T" + this.statusInput.timeStart,
        endDate: endDate + "T" + this.statusInput.timeEnd,
      };
      let file = await this.reportStatus(body);
      downloadFile(file);
      this.loading = false;
    },

    async downloadReward(startDate, endDate) {
      this.loading = true;
      const payload = {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
      };
      const file = await this.reportReward_v2(payload);
      downloadFile(file + ".xlsx");
      this.loading = false;
    },

    async downloadClientsReport(startDate, endDate) {
      let body = {
        startTime: new Date(startDate).toISOString(),
        endTime: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
        lifeTimePeriods: this.clientsInput.lifeTimePeriods,
        isMonth: this.clientsInput.isMonth,
      };
      this.reportClients(body);
      ShowMessage(
        "Файл начал формироваться, список сформированных файлов вы можете посмотреть выше",
        true
      );
    },
    // отчет по дистанции
    async downloadDistanceReport(startDate, endDate) {
      let body = {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
      };
      this.loading = true;
      let file = await this.reportDistance(body);
      downloadFile(file);
      this.loading = false;
    },

    // отчет по компенсациям работников
    async downloadCompensationReport(startDate, endDate) {
      this.loading = true;
      let body = {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
      };
      let file = await this.reportCompensation(body);
      downloadFile(file);
      this.loading = false;
    },
    async downloadAirbaReport(startDate, endDate) {
      this.loading = true;
      let body = {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate)
          .toISOString()
          .replace("00:00:00", "23:59:59"),
      };
      let file = await this.reportAirba(body);
      downloadFile(file);
      this.loading = false;
    },

    // отчет по продажам и по онлайн продажам
    async downloadSalesReport(startDate, endDate, onlineType) {
      this.loading = true;
      if (!this.salesTypeId) {
        ShowMessage("Выберите тип отчета");
        this.loading = false;
        return;
      }
      if (this.salesTypeId !== 3 && !this.salesDepartment) {
        ShowMessage("Выберите филиал");
        this.loading = false;
        return;
      }
      let file;
      const payload = {
        onlineType,
        departmentId: this.salesDepartment,
        body: {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate)
            .toISOString()
            .replace("00:00:00", "23:59:59"),
        },
      };
      // тип отчета
      switch (this.salesTypeId) {
        case 1:
          file = await this.reportSalesClose(payload);
          break;
        case 2:
          file = await this.reportSalesOpen(payload);
          break;
        case 3:
          file = await this.reportPlatform(payload);
          break;
      }
      downloadFile(file);
      this.loading = false;
    },

    //Вспомогательная функция создает массив от 1 до заданного значения
    createPeriods(maxValue) {
      let arr = [...Array(maxValue).keys()].map((key) => key + 1);
      return arr;
    },
    //возвращает необходимую функцию
    getList() {
      return api.File.getClientsReportsList;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  &__conteiner {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 50%;
    @media (max-width: 500px) {
      width: 100%;
      justify-content: center;
    }
  }
  &__text {
    margin: 0;
  }
}
.table {
  margin-top: 20px;
  &_header {
    background: linear-gradient(to right, #5d4bd0, #e93e7b);
  }
  th {
    background: none !important;
    color: white !important;
    @media (max-width: 500px) {
      padding: 0 3px !important;
    }
  }
}
.file-input {
  border: #51c8f0 2px solid !important;
  border-radius: 20px;
}
</style>
