<template>
  <tr class="file-row">
    <td class="file-row_text">{{ text }}</td>
    <td>
      <slot name="type"></slot>
    </td>
    <td>
      <slot name="filials"></slot>
    </td>
    <td>
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            readonly
            v-bind="attrs"
            solo
            dense
            hide-details
            class="date-input"
            v-on="on"
          ></v-text-field>
        </template>
        <slot name="startTimePicker"></slot>
        <v-date-picker v-model="startDate" :max="endDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="#51c8f0" @click="menu1 = false">Cancel</v-btn>
          <v-btn text color="#51c8f0" @click="$refs.menu1.save(startDate)"
            >OK</v-btn
          >
        </v-date-picker>
      </v-menu>
    </td>
    <td>
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="endDate"
            readonly
            v-bind="attrs"
            solo
            dense
            hide-details
            class="date-input"
            v-on="on"
          ></v-text-field>
        </template>
        <slot name="endTimePicker"></slot>
        <v-date-picker v-model="endDate" :min="startDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="#51c8f0" @click="menu2 = false">Cancel</v-btn>
          <v-btn text color="#51c8f0" @click="$refs.menu2.save(endDate)"
            >OK</v-btn
          >
        </v-date-picker>
      </v-menu>
    </td>
    <td>
      <v-btn
        color="#e93e7b"
        rounded
        dark
        small
        @click="download(startDate, endDate, online)"
        >Сформировать</v-btn
      >
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    download: {
      type: Function,
      default: () => {},
    },
    text: {
      type: String,
      default: "",
    },
    withDates: {
      type: Boolean,
      default: true,
    },
    online: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      endDate: new Date().toISOString().substring(0, 10),
      startDate: new Date().toISOString().substring(0, 10),
    };
  },
};
</script>

<style lang="scss">
.date-input {
  border: #51c8f0 2px solid !important;
  border-radius: 20px;
}
.file-row {
  &_text {
    color: black;
    font-weight: 700;
  }
  td {
    border-bottom: solid 1px #e93e7a45 !important;
    padding: 5px 15px !important;
    @media (max-width: 500px) {
      padding: 0 3px !important;
      min-width: 100px !important;
    }
  }
}
</style>
